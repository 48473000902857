import {
  Button, ButtonGroup, Card,
  CardBody, CardFooter, Divider,
  Image, Stack, Heading, Text
} from '@chakra-ui/react'
import ProductData from '../entities/ProductData'

const ProductCard = (p: ProductData) => {
  return (
    <Card maxW='sm'>
      <CardBody>
        <Image
          src={p.image}
          alt={p.title}
          borderRadius='lg'
        />
        <Stack mt='6' spacing='3'>
          <Heading size='md'>{p.title}</Heading>
          <Text>
            {p.description}
          </Text>
          <Text color='blue.600' fontSize='2xl'>
            ${p.price}
          </Text>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <ButtonGroup spacing='2'>
          <Button variant='solid' colorScheme='blue'>
            Buy now
          </Button>
          <Button variant='ghost' colorScheme='blue'>
            Add to cart
          </Button>
        </ButtonGroup>
      </CardFooter>
    </Card>
  )
}

export default ProductCard