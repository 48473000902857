import ProductCard from "../../components/ProductCard"
import { Grid } from "@chakra-ui/react"
import { useState, useEffect } from "react";
import ProductData from "../../entities/ProductData"


const Products = () => {
  const [products, setProducts] = useState<ProductData[]>([]);

  useEffect(() => {
    fetch("./data/products.json")
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, []);
  return (
    <Grid gap={2} autoFlow="row dense">
      {products.map((item: ProductData) => (
        <ProductCard
          key={item.id}
          id={item.id}
          image={item.image}
          title={item.title}
          description={item.description}
          price={item.price} />
      ))}
    </Grid>
  )
}

export default Products