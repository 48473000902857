// import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import Products from './pages/Products/Index';
// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <ChakraProvider>
      <Products />
    </ChakraProvider>
  );
}

export default App;
